// import logo from './logo.svg';
// import './App.css';
import React from "react";
// import Section from './components/Section';
// import Styled from './components/Styled'
// import Counter from './components/Counter'
// import Welcome from './components/Welcome';
// import Tile from './components/Tile';
// import Product from './components/Product';
import Header from './components/Header';
import "./index.css";





function App() {
  return (
    <>

    < Header />
    </>
    
    
    
    


    










  );
}

export default App;
